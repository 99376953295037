<template>
  <div id="leave-review">
    <Loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      loader="dots"
      color="#198b4a"
    ></Loading>
    <WhiteHeader v-if="store" :bannerImage="store.bannerLink" :showNavigation="false"></WhiteHeader>
    <div id="store-info">
      <Store id="store" :store="store" :shouldShowMenu="false"></Store>
    </div>
    <div id="mask" @click="onClickOutside" :class="{ mask: isEntranceOpen || isLanguageModalOpen }"></div>
    <div id="body">
      <div id="leave-review-container">
        <div id="review-header">
          <div id="personal-data-info">
            <font-awesome-icon @click="toggleInfo" id="info-icon" icon="info-circle"></font-awesome-icon>
            <div v-if="showInfo" id="info-message-container">
              <p>{{ labels.review.personalDataAgreement[language] }}</p>
            </div>
          </div>
          <div id="labels">
            <h4 id="store-label">{{ labels.review.howWasIt[language] }}</h4>
          </div>
        </div>
        <div id="review-body">
          <div id="stars">
            <font-awesome-icon
              @click="rate(1)"
              @mouseover="onHover(1)"
              @mouseout="onHoverOut(1)"
              id="star-1"
              :class="{
                starIcon: true,
                yellow:
                  isHovering5 ||
                  isHovering4 ||
                  isHovering3 ||
                  isHovering2 ||
                  isHovering1 ||
                  rating > 0,
              }"
              icon="star"
            ></font-awesome-icon>
            <font-awesome-icon
              @click="rate(2)"
              @mouseover="onHover(2)"
              @mouseout="onHoverOut(2)"
              id="star-2"
              :class="{
                starIcon: true,
                yellow:
                  isHovering5 ||
                  isHovering4 ||
                  isHovering3 ||
                  isHovering2 ||
                  rating > 1,
              }"
              icon="star"
            ></font-awesome-icon>
            <font-awesome-icon
              @click="rate(3)"
              @mouseover="onHover(3)"
              @mouseout="onHoverOut(3)"
              id="star-3"
              :class="{
                starIcon: true,
                yellow: isHovering5 || isHovering4 || isHovering3 || rating > 2,
              }"
              icon="star"
            ></font-awesome-icon>
            <font-awesome-icon
              @click="rate(4)"
              @mouseover="onHover(4)"
              @mouseout="onHoverOut(4)"
              id="star-4"
              :class="{
                starIcon: true,
                yellow: isHovering5 || isHovering4 || rating > 3,
              }"
              icon="star"
            ></font-awesome-icon>
            <font-awesome-icon
              @click="rate(5)"
              @mouseover="onHover(5)"
              @mouseout="onHoverOut(5)"
              id="star-5"
              :class="{ starIcon: true, yellow: isHovering5 || rating > 4 }"
              icon="star"
            ></font-awesome-icon>
          </div>
          <div id="review-container">
            <h3 id="review-label">{{ labels.review.comment[language] }}:</h3>
            <textarea
              :disabled="hasLeftReview"
              name="review"
              ref="review"
              id="review"
              v-model="review"
              cols="30"
              rows="10"
            ></textarea>
          </div>
          <Button
            v-if="rating > 0"
            :loading="leaveReviewButtonLoading"
            @click="leaveReview"
            :disabled="rating < 1 || hasLeftReview || leaveReviewButtonLoading"
            :class="{ leftReview: hasLeftReview }"
            id="review-button"
          >
            {{
            !hasLeftReview
            ? labels.review.leaveReview[language]
            : labels.review.thanksForReviewing[language]
            }}
          </Button>
          <h4 v-if="apiError" id="error">Κάτι πήγε λάθος</h4>
        </div>
      </div>
      <div id="more-reviews">
        <h3>{{ labels.review.moreReviews[language] }}</h3>
        <font-awesome-icon id="down" icon="chevron-down"></font-awesome-icon>
      </div>
      <div ref="other" id="other-reviews">
        <InfiScroller
          :scroll-target="refOther"
          :has-more="hasMore"
          :on-load-more="onLoadMore"
          :gutter="100"
        >
          <Review v-for="(review, index) in reviews" :key="index" :review="review"></Review>
          <div id="no-reviews" v-if="emptyContent">
            <h4>
              {{ labels.review.noOtherReviews[language] }}
              <span
                style="font-style: normal !important; margin-left: 5px"
              >🥺</span>
            </h4>
          </div>
        </InfiScroller>
        <div id="loading-more" v-if="hasMore">
          <h4>{{ labels.review.loadingMore[language] }} ⌛</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Review from "../Review";
import WhiteHeader from "../WhiteHeader";
import Store from "../Store";
import Button from "../Button";
import ProfileDropdown from "../ProfileDropdown.vue"

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import InfiScroller from "vue-infi-scroller";

import StoreApi from "../../api/stores";
import OrderApi from "../../api/order";

export default {
  name: "LeaveReview",
  components: {
    Review,
    WhiteHeader,
    Loading,
    Store,
    InfiScroller,
    Button,
    ProfileDropdown
  },
  beforeRouteLeave(to, from, next) {
    if (this.isLanguageModalOpen) {
      this.$store.dispatch("closeLanguageModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else {
      next();
    }
  },
  async mounted() {
    window.scrollTo(0, 0);
    let order = await OrderApi.getPastOrder(
      this.$router.currentRoute.params.orderId,
      this.authToken,
      this.jwt
    );

    let result = await StoreApi.getStoreReviews(order.store.id, 0);

    this.store = order.store;
    if (order.review != null) {
      this.rating = order.review.rating;
      this.storeReview = order.review.comment;
      this.hasLeftReview = true;
    }
    this.reviews = result.content;
    this.hasMore = !result.last;

    this.refOther = this.$refs.other;

    this.isLoading = false;
    this.setTitle();
  },
  computed: {
    review: {
      get() {
        return this.storeReview;
      },

      set(currentReview) {
        this.storeReview = currentReview;
      },
    },

    isEntranceOpen() {
      return this.$store.getters.getSlideEntranceIn;
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    authToken() {
      return this.$store.getters.getUserAuthToken;
    },

    jwt() {
      return this.$store.getters.getUserJWT;
    },

    isLanguageModalOpen() {
      return this.$store.getters.isLanguageModalOpen;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    storeName() {
      if (this.store) {
        return this.store.names && this.store.names.translation[this.language]
          ? this.store.names.translation[this.language].name
          : this.store.name;
      }
    },

    emptyContent() {
      return this.reviews.length == 0;
    },

    title() {
      if (this.store) {
        return `${this.labels.titles.leaveReview[this.language]} - ${this.storeName
          } | Fluter`;
      } else {
        return `${this.labels.titles.leaveReview[this.language]} | Fluter`;
      }
    },
  },
  props: [],
  data() {
    return {
      storeReview: "",
      isHovering5: false,
      isHovering4: false,
      isHovering3: false,
      isHovering2: false,
      isHovering1: false,
      rating: 0,
      reviews: [],
      isLoading: true,
      hasLeftReview: false,
      store: undefined,
      refOther: undefined,
      page: 1,
      hasMore: true,
      leaveReviewButtonLoading: false,
      apiError: false,
      showInfo: false,
    };
  },
  methods: {
    onLoadMore: async function () {
      let result = await StoreApi.getStoreReviews(this.store.id, this.page);
      this.page++;
      this.hasMore = !result.last;
      this.reviews = [...this.reviews, ...result.content];
    },

    leaveReview: async function () {
      this.leaveReviewButtonLoading = true;
      let result = await OrderApi.postReview(
        {
          id: this.$router.currentRoute.params.orderId,
          comment: this.storeReview,
          rating: this.rating,
        },
        this.authToken,
        this.jwt
      );
      if (result != "Κάτι πήγε λάθος") {
        this.hasLeftReview = true;
      } else {
        this.apiError = true;
      }
      this.leaveReviewButtonLoading = false;
    },

    rate: function (rating) {
      if (!this.hasLeftReview) {
        this.rating = rating;
      }
    },

    onHover: function (starNumber) {
      if (this.hasLeftReview) {
        return;
      }

      if (starNumber >= 1) {
        this.isHovering1 = true;
      }

      if (starNumber >= 2) {
        this.isHovering2 = true;
      }

      if (starNumber >= 3) {
        this.isHovering3 = true;
      }

      if (starNumber >= 4) {
        this.isHovering4 = true;
      }

      if (starNumber >= 5) {
        this.isHovering5 = true;
      }
    },

    onHoverOut: function (starNumber) {
      if (this.hasLeftReview) {
        return;
      }

      if (starNumber >= 1) {
        this.isHovering1 = false;
      }

      if (starNumber >= 2) {
        this.isHovering2 = false;
      }

      if (starNumber >= 3) {
        this.isHovering3 = false;
      }

      if (starNumber >= 4) {
        this.isHovering4 = false;
      }

      if (starNumber >= 5) {
        this.isHovering5 = false;
      }
    },

    initiateEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceIn");
    },

    onClickOutside: function (event) {
      if (this.isEntranceOpen && event.target.id == "mask") {
        this.closeEntrance();
      } else if (this.isLanguageModalOpen) {
        this.$store.dispatch("closeLanguageModal");
      }
    },

    closeEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceOut");

      this.$store.dispatch("shouldNotSlideEntranceIn");

      setTimeout(() => {
        this.$store.dispatch("resetEntranceState");
        this.$store.dispatch("setEntranceMessage", "");

        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");
      }, 850);
    },

    toggleInfo: function () {
      this.showInfo = !this.showInfo;
    },

    setTitle() {
      try {
        document.title = this.title;
      } catch (error) {
        console.log("Could not set title");
      }
    }
  },
};
</script>

<style scoped>
#leave-review {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 201;
  opacity: 1;
}

#body {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7f7f7;
  width: 100vw;
  min-height: 85vh;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.22);
}

#leave-review-container {
  transform: translateY(-20vh);
  width: 33%;
  min-height: 300px;
  height: 60vh;
  display: flex;
  flex-direction: column;
  background: #f7f7f7;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.16), 2px 2px 4px rgba(0, 0, 0, 0.23);
  border-radius: 16px;
}

#review-header {
  display: flex;
  align-items: center;
  position: relative;
  background: white;
  flex: 1;
  /* height: 12%; */
  /* min-height: 12%; */
  padding: 3% 0 3% 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.16), 0 2px 2px rgba(0, 0, 0, 0.23);
}

#personal-data-info {
  position: absolute;
  top: 10px;
  right: 20px;
  /* background: blue; */
}
@media screen and (max-width: 310px) {
  #personal-data-info {
    right: 8px;
    top: 5px;
    font-size: 0.8rem;
  }
}

#info-icon {
  color: gray;
  cursor: pointer;
}

#info-message-container {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0%, 100%);
  width: 80vw;
  max-width: 400px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px;
  min-width: 20vw;
  font-size: 12px;
  background: white;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.23), 3px 3px 4px rgba(0, 0, 0, 0.36);
  border-radius: 16px;
  z-index: 202;
}

#info-message-container > p {
  margin: 0;
  z-index: 202;
}

#labels {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

#store-label,
#review-title {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

#store-label {
  /* margin: 0; */
  font-size: 18px;
  /* margin-left: 10%; */
  width: 100%;
  display: flex;
  justify-content: center;
  /* background: blue; */
  color: #198b4a;
}

#review-body {
  flex: 9;
  /* height: 88%; */
  /* min-height: 88%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

#stars {
  /* background: blue; */
  width: 100%;
  /* height: 25%; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}

#store-name {
  width: 100%;
  padding: 2%;
  margin: 0;
}

#store-name > h3 {
  font-size: 22px;
  color: #198b4a;
  font-weight: bold;
}

#leave-a-review-label > h3 {
  font-size: 18px;
  font-weight: 600;
  color: #db6f6e;
  margin: 0;
}

.starIcon {
  margin-right: 10px;
  width: 30px;
  height: 30px;
  color: #cecece;
  cursor: pointer;
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.23); */
}

.yellow {
  color: #ffc200;
}

.gray {
  background: #cecece !important;
}

.space {
  margin-right: 10px;
}

textarea {
  resize: none;
  outline: none;
  border: none;
  background: white;
  width: 100%;
  justify-self: center;
  align-self: center;
  min-height: 10%;
  max-height: 90px;
  box-shadow: inset 1px 1px 4px 0 rgba(0, 0, 0, 0.2),
    inset -1px -1px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2),
    inset 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 7px 10px 7px 10px;
  /* font-size: 16px; */
  /* padding-left: 10px;
  padding-top: 7px; */
}

#review-container {
  display: flex;
  width: 80%;
  flex-direction: column;
  font-size: 16px;
}

#review-label {
  margin-bottom: 2vh;
  color: gray;
  font-size: 14px;
}

#review-button {
  width: 80%;
  background: linear-gradient(62deg, #ff8c47, #ffb587);
  color: white;
  font-size: 14px;
  font-weight: 600;
  border: none;
}

#review-button:disabled {
  opacity: 0.75;
}

.leftReview {
  background: #198b4a !important;
}

#error {
  margin: 0;
  color: #f30;
  font-weight: 600;
  font-size: 14px;
  max-width: 50%;
}

#more-reviews {
  /* margin-top: 5%; */
  transform: translateY(-10vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

#more-reviews > h3 {
  color: gray;
  font-size: 14px;
}

#down {
  color: gray;
  width: 25px;
  height: 25px;
}

#other-reviews {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  max-height: 80vh;
  min-height: 200px;
  /* max-height: 100px; */
  overflow-y: scroll;
  background: white;
  border-radius: 20px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.24), 1px 2px 4px rgba(0, 0, 0, 0.36);
  margin: 5% 0 5% 0;
  padding: 2% 0 5% 0;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

#other-reviews::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

#random-review {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5%;
  border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
}

#date {
  width: 100%;
  margin: 0;
  padding: 1%;
  font-size: 14px;
  color: #198b4a;
  font-weight: 600;
}

#comment {
  margin: 0;
  color: grey;
  font-style: italic;
  font-size: 16px;
  padding-bottom: 1%;
}

#loading-more {
  margin: 10% 0 0% 0;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#loading-more > h4 {
  margin: 0;
  color: grey;
  font-style: italic;
  font-size: 14px;
  padding-bottom: 1%;
  text-align: center;
}

#store-info {
  position: absolute;
  top: 20vh;
  right: 20px;
  width: 25%;
  padding-left: 1%;
  min-width: 400px;
  background: white;
  border-radius: 16px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

#store {
  cursor: unset;
}

.hide {
  visibility: hidden;
}

#no-reviews {
  margin: auto;
  width: 90%;
}
@media screen and (max-width: 800px) {
  #no-reviews {
    width: 80%;
  }
}

#no-reviews > h4 {
  margin: auto;
  color: grey;
  font-style: italic;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}
@media screen and (max-width: 800px) {
  #no-reviews > h4 {
    font-size: 14px;
    font-weight: 600;
  }
}

@media screen and (max-width: 800px) {
  #leave-review-container {
    width: 90vw;
    transform: translateY(-20px);
    height: 45vh;
  }

  #more-reviews {
    margin-top: unset;
    margin: 20px 0 40px 0;
    /* margin-top: 12%;
    margin-bottom: 12%; */
    transform: translateY(20px);
  }

  #store-name {
    padding: 5%;
  }

  #store-label {
    font-size: 16px;
  }

  #leave-a-review {
    width: 80%;
    padding-top: 3%;
    padding-bottom: 5%;
    /* margin-top: 12.5%; */
    height: 50vh;
  }

  #review-button {
    margin-top: 8%;
    font-size: 12px;
    padding: 7.5px 0 7.5px 0;
  }

  #leave-a-review-label > h3 {
    font-size: 16px;
  }

  .starIcon {
    width: 20px;
    height: 20px;
  }

  #review-label {
    font-size: 12px;
  }

  #error {
    font-size: 12px;
  }

  textarea {
    max-height: 65px;
    font-size: 14px;
  }

  #other-reviews {
    width: 80%;
  }

  /* #more-reviews {
    margin-top: 20%;
  } */

  #more-reviews > h3 {
    font-size: 12px;
  }

  #down {
    width: 20px;
    height: 20px;
  }

  #store-info {
    top: 130px;
    width: 90%;
    left: 5%;
    min-width: 200px;
  }
}

#profile-dropdown {
  position: absolute;
  top: 69px;
  right: 20px;
  z-index: 201;
}
@media screen and (max-width: 800px) {
  #profile-dropdown {
    top: 67px;
  }
}

@media (max-height: 730px) and (max-width: 550px) {
  #leave-review-container {
    transform: translateY(10px);
  }
}

@media (max-height: 650px) {
  #leave-review-container {
    transform: translateY(20px);
    min-height: 300px;
  }
}
</style>
